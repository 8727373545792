import React from 'react';
import { Outlet } from 'react-router-dom';
import CommonHeader from '../CommonHeader/CommonHeader';
import useFetchData from "../UseFetchData";

const Layout = ({ tagId }) => {
  const { data: serverData, loading, error } = useFetchData();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="flex flex-col min-h-screen">
      <CommonHeader tagId={tagId} serverData={serverData} />
      <Outlet context={{ serverData, tagId }} />
    </div>
  );
};

export default Layout;
