import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Modal from "../Modal/Modal";
import settingsImage from "../../assets/isettings.png";
import inventoryCategories from "../InventoryCategories/InventoryCategories";
import { filterNAValues } from "../Utility/DataHelpers";
import StatusCards from "../StatusCards/StatusCards";

const ServerInventory = () => {
  const { serverData, tagId } = useOutletContext();
  const [modalState, setModalState] = useState({
    isOpen: false,
    content: null,
  });

  const navigate = useNavigate();

  const openModal = (title, content) => {
    setModalState({ isOpen: true, content: { title, content } });
  };

  const closeModal = () => setModalState({ isOpen: false, content: null });

  const handleViewMore = (category) => {
    const data = serverData[category];
    const headers = Object.keys(data[0]).filter(header => header !== 'name');

    const formatHeader = (header) => {
      switch (header) {
        case 'manufacturer': return 'Make';
        case 'part_id': return 'Part ID';
        case 'description': return 'Description';
        case 'label': return 'Label';
        default: return header.charAt(0).toUpperCase() + header.slice(1).replace(/_/g, ' ');
      }
    };

    const content = (
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header} className="border border-gray-300 px-4 py-2 bg-gray-100">
                {formatHeader(header)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {headers.map((header) => (
                <td key={header} className="border border-gray-300 px-4 py-2">
                  {row[header]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );

    openModal(category, content);
  };

  const getSummary = (category) => {
    const data = filterNAValues(serverData[category]);
    if (data.length === 0) return ["No data available"];
    switch (category) {
      case "Processor":
        const groupedCPU = data.reduce((acc, cpu) => {
          const key = cpu.part_id;
          if (!acc[key]) acc[key] = { Qty: 0 };
          acc[key].Qty += 1;
          return acc;
        }, {});

        return Object.keys(groupedCPU).map((model) =>
          `${model}, Qty: ${groupedCPU[model].Qty}`
        );

      case "Memory":
        const groupedMemory = data.reduce((acc, mem) => {
          if (!mem.manufacturer) return acc;
          const key = `${mem.manufacturer} ${mem.part_id} ${mem.description}`;
          if (!acc[key]) acc[key] = { Qty: 0 };
          acc[key].Qty += 1;
          return acc;
        }, {});

        return Object.keys(groupedMemory).map((key) =>
          `${key}, Qty: ${groupedMemory[key].Qty}`
        );

      case "Storage":
        const groupedStorage = data.reduce((acc, storage) => {
          const manufacturer = storage.manufacturer || "N/A";
          const part_id = storage.part_id || "N/A";
          const key = `${manufacturer} ${part_id} ${storage.description}`;
          if (!acc[key]) acc[key] = { Qty: 0 };
          acc[key].Qty += 1;
          return acc;
        }, {});

        return Object.keys(groupedStorage).map((key) =>
          `${key}, Qty: ${groupedStorage[key].Qty}`
        );

      case "Network":
        const groupedNetwork = data.reduce((acc, net) => {
          const key = `${net.manufacturer} ${net.part_id}`;
          if (!acc[key]) acc[key] = { Qty: 0 };
          acc[key].Qty += 1;
          return acc;
        }, {});

        return Object.keys(groupedNetwork).map((key) =>
          `${key}, Qty: ${groupedNetwork[key].Qty}`
        );

      default:
        return ["Summary not available"];
    }
  };

  return (
    <main className="flex-grow bg-white">
      <div className="max-w-6xl mx-auto px-4 py-4">
        <StatusCards />
      </div>
      <div className="max-w-6xl mx-auto px-4 py-4">
        <h2 className="text-2xl font-bold text-center pb-3 mb-3">
          Server Inventory for {tagId}
        </h2>
        <div className="grid grid-cols-4 gap-4">
          {inventoryCategories
            .filter(category => category.name !== "Server")
            .map(({ name, icon }) => (
              <div key={name} className="border p-4 rounded-lg shadow">
                <div className="flex items-center mb-2">
                  <img src={icon} alt={name} className="w-6 h-6 mr-2" />
                  <h3 className="text-lg font-semibold">{name}</h3>
                </div>
                <ul className="mb-2">
                  {serverData &&
                    getSummary(name).map((data, index) => (
                      <li key={index}>{data}</li>
                    ))}
                </ul>
                {serverData && serverData[name] && (
                  <button
                    onClick={() => handleViewMore(name)}
                    className="text-customBlue hover:underline"
                  >
                    View more...
                  </button>
                )}
              </div>
            ))}
        </div>
        <div className="flex justify-center mt-8">
          <div
            className="flex items-center hover:cursor-pointer"
            onClick={() => navigate("/configuration-settings")}
          >
            <img
              src={settingsImage}
              alt="Configuration Settings"
              className="w-15 h-15 mr-4"
            />
            <p className="text-2xl font-bold">Configuration Settings</p>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalState.isOpen}
        onClose={closeModal}
        title={modalState.content?.title}
        content={modalState.content?.content}
      />
    </main>
  );
};

export default ServerInventory;
