import React from "react";
import Header from "../Header/Header";

const CommonHeader = ({ tagId, serverData }) => {
  const serverTableData = serverData?.Server?.[0] || {};

  return (
    <>
      <Header tagId={tagId} />
      <div className="bg-white">
        <div className="max-w-6xl mx-auto px-4 py-6">
          <table className="w-full border-collapse border border-gray-300 bg-white">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 px-4 py-2">Serial Number</th>
                <th className="border border-gray-300 px-4 py-2">Make</th>
                <th className="border border-gray-300 px-4 py-2">Model</th>
                <th className="border border-gray-300 px-4 py-2">SKU</th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-blue-100 font-semibold">
                <td className="border border-gray-300 px-4 py-2">{serverTableData.serial || 'N/A'}</td>
                <td className="border border-gray-300 px-4 py-2">{serverTableData.manufacturer || 'N/A'}</td>
                <td className="border border-gray-300 px-4 py-2">{serverTableData.model || 'N/A'}</td>
                <td className="border border-gray-300 px-4 py-2">{serverTableData.sku || 'N/A'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-full h-2 bg-customBlue"></div>
    </>
  );
};

export default CommonHeader;
