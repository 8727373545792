import React, { useState, useEffect, useCallback } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import maintenance from "../../assets/mainetenance.png";
import timezone from "../../assets/timezone.png";
import fan from "../../assets/fan.png";
import virtualMedia from "../../assets/virtualMedia.png";
import settingsImage from "../../assets/isettings.png";
import laptopIcon from "../../assets/laptop.png";
import axios from "axios";

const LoadingButton = ({ isLoading, onClick, disabled, children }) => (
  <button
    className="bg-customBlue text-white px-4 py-2 rounded flex items-center justify-center"
    onClick={onClick}
    disabled={disabled || isLoading}
  >
    {isLoading ? (
      <>
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        {children}
      </>
    ) : (
      children
    )}
  </button>
);

const ConfigurationSettings = () => {
  const { tagId } = useOutletContext();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("SystemMaintenance");
  const [isLoading, setIsLoading] = useState(false);  // Start with loading false

  const configItems = [
    { id: "SystemMaintenance", text: "System Maintenance", icon: maintenance },
    { id: "PowerLimit", text: "Power Limit", icon: fan },
    { id: "AssetTag", text: "Asset Tag", icon: timezone },
    { id: "IndicatorLED", text: "Indicator LED", icon: virtualMedia },
  ];

  useEffect(() => {
    // Set isLoading to false when System Maintenance tab is active
    if (activeTab === "SystemMaintenance") {
      setIsLoading(false);
    }
  }, [activeTab]);

  const renderContent = () => {
    switch (activeTab) {
      case "SystemMaintenance":
        return <SystemSettingsContent serverName={tagId} />;
      case "PowerLimit":
        return <PowerLimitContent serverName={tagId} isLoading={isLoading} setIsLoading={setIsLoading} />;
      case "AssetTag":
        return <AssetTagContent serverName={tagId} isLoading={isLoading} setIsLoading={setIsLoading} />;
      case "IndicatorLED":
        return <LedIndicatorContent serverName={tagId} isLoading={isLoading} setIsLoading={setIsLoading} />;
      default:
        return null;
    }
  };

  return (
    <main className="relative min-h-screen bg-white">
      <div className="flex items-center mb-10 pt-8 pl-20">
        <img
          src={settingsImage}
          alt="Settings Icon"
          className="mr-4 h-15 w-15"
        />
        <h1 className="text-2xl font-extrabold">
          Configuration Settings for {tagId}
        </h1>
      </div>
      <div className="flex max-w-6xl mx-auto px-4">
        <div className="w-1/3 pr-4">
          {configItems.map((item) => (
            <ConfigTab
              key={item.id}
              {...item}
              isActive={activeTab === item.id}
              onClick={() => !isLoading && setActiveTab(item.id)}
              disabled={isLoading}
            />
          ))}
        </div>
        <div className="w-2/3 pl-4 border-l">
          {renderContent()}
        </div>
      </div>
      <div
        className="flex items-center hover:cursor-pointer mt-8 pl-20"
        onClick={() => !isLoading && navigate("/")}
      >
        <img
          src={laptopIcon}
          alt="Server Inventory"
          className="w-15 h-15 mr-4"
        />
        <p className={`text-2xl font-bold ${isLoading ? 'text-gray-400' : ''}`}>Back to Server Inventory</p>
      </div>
    </main>
  );
};

const ConfigTab = ({ icon, text, isActive, onClick, disabled }) => (
  <div
    className={`flex items-center space-x-4 cursor-pointer p-4 ${
      isActive ? "bg-gray-200" : "hover:bg-gray-100"
    } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
    onClick={onClick}
  >
    <img src={icon} alt={`${text} Icon`} className="w-10 h-10" />
    <span className={`text-xl font-bold ${isActive ? "text-customBlue" : ""}`}>
      {text}
    </span>
  </div>
);

const SystemSettingsContent = ({ serverName }) => {
  const [selectedAction, setSelectedAction] = useState("Hard Reset");

  const performAction = () => {
    console.log(`Performing action: ${selectedAction}`);
    // Simulate API call
    // Note: We're not setting any loading state here as it's not needed for this tab
  };

  return (
    <div>
      <p className="text-customBlue mb-4">Host is currently on</p>
      <div className="border rounded-lg p-4">
        <h3 className="font-bold text-lg mb-4">Power Actions</h3>
        {["Power Off", "Power On", "Power Cycle", "Hard Reset", "ACPI Shutdown"].map((action) => (
          <div key={action} className="mb-2">
            <input
              type="radio"
              id={action}
              name="powerAction"
              value={action}
              checked={selectedAction === action}
              onChange={() => setSelectedAction(action)}
              className="mr-2"
            />
            <label htmlFor={action}>{action}</label>
          </div>
        ))}
      </div>
      <LoadingButton
        isLoading={false}  // Since this tab doesn't have loading state
        onClick={performAction}
      >
        Perform Action
      </LoadingButton>
    </div>
  );
};

const PowerLimitContent = ({ serverName, isLoading, setIsLoading }) => {
  const [currentPowerLimit, setCurrentPowerLimit] = useState(1200);
  const [selectedPowerLimit, setSelectedPowerLimit] = useState(1200);
  const [error, setError] = useState(null);

  const fetchPowerLimit = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `https://demoapi.asama.cloud/management/${serverName}/powerLimit`
      );
      setCurrentPowerLimit(response.data.LimitInWatts);
      setSelectedPowerLimit(response.data.LimitInWatts);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [serverName, setIsLoading]);

  useEffect(() => {
    fetchPowerLimit();
  }, [fetchPowerLimit]);

  const handlePowerLimitChange = (event) => {
    setSelectedPowerLimit(parseInt(event.target.value, 10));
  };

  const savePowerLimit = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await axios.put(
        `https://demoapi.asama.cloud/management/${serverName}/powerLimit`,
        { LimitInWatts: selectedPowerLimit }
      );
      setCurrentPowerLimit(selectedPowerLimit);
    } catch (err) {
      setError(err.message);
      setSelectedPowerLimit(currentPowerLimit); // Reset to previous value on error
    } finally {
      setIsLoading(false);
    }
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <div className="border rounded-lg p-4">
        <input
          type="range"
          min="360"
          max="1200"
          step="120"
          value={selectedPowerLimit}
          onChange={handlePowerLimitChange}
          className="w-full mb-2"
          disabled={isLoading}
        />
        <div className="flex justify-between text-sm text-gray-600 mb-2">
          <span className="text-center">
            <div>360W</div>
            <div>(30%)</div>
          </span>
          <span className="text-center">
            <div>1200W</div>
            <div>(100%)</div>
          </span>
        </div>
        <p className="text-center font-bold">
          Selected: {selectedPowerLimit}W ({Math.round((selectedPowerLimit / 1200) * 100)}%)
        </p>
        <p className="text-center text-sm text-gray-600">
          Current: {currentPowerLimit}W ({Math.round((currentPowerLimit / 1200) * 100)}%)
        </p>
      </div>
      <div className="flex justify-center mt-4">
        <LoadingButton
          isLoading={isLoading}
          onClick={savePowerLimit}
          disabled={selectedPowerLimit === currentPowerLimit}
        >
          {isLoading ? (currentPowerLimit === 1200 ? "Loading..." : "Saving...") : "Save"}
        </LoadingButton>
      </div>
    </div>
  );
};

const AssetTagContent = ({ serverName, isLoading, setIsLoading }) => {
  const [assetTag, setAssetTag] = useState("");
  const [newAssetTag, setNewAssetTag] = useState("");
  const [error, setError] = useState(null);

  const fetchAssetTag = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `https://demoapi.asama.cloud/management/${serverName}/assetTag`
      );
      setAssetTag(response.data.AssetTag);
      setNewAssetTag(response.data.AssetTag);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [serverName, setIsLoading]);

  useEffect(() => {
    fetchAssetTag();
  }, [fetchAssetTag]);

  const handleSave = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await axios.put(
        `https://demoapi.asama.cloud/management/${serverName}/assetTag`,
        { AssetTag: newAssetTag }
      );
      setAssetTag(newAssetTag);
    } catch (err) {
      setError(err.message);
      setNewAssetTag(assetTag); // Reset to previous value on error
    } finally {
      setIsLoading(false);
    }
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <input
        type="text"
        value={newAssetTag}
        onChange={(e) => setNewAssetTag(e.target.value)}
        className="w-full p-2 border rounded"
        placeholder="Enter new asset tag"
        disabled={isLoading}
      />
      <div className="flex justify-center mt-4">
        <LoadingButton
          isLoading={isLoading}
          onClick={handleSave}
          disabled={newAssetTag === assetTag}
        >
          {isLoading ? (assetTag === "" ? "Loading..." : "Saving...") : "Save"}
        </LoadingButton>
      </div>
    </div>
  );
};

const LedIndicatorContent = ({ serverName, isLoading, setIsLoading }) => {
  const [selectedAction, setSelectedAction] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [error, setError] = useState(null);

  const fetchLedStatus = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `https://demoapi.asama.cloud/management/${serverName}/indicatorLED`
      );
      setCurrentStatus(response.data.IndicatorLED);
      setSelectedAction(response.data.IndicatorLED);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [serverName, setIsLoading]);

  useEffect(() => {
    fetchLedStatus();
  }, [fetchLedStatus]);

  const performAction = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await axios.put(
        `https://demoapi.asama.cloud/management/${serverName}/indicatorLED`,
        { IndicatorLED: selectedAction }
      );
      setCurrentStatus(selectedAction);
    } catch (err) {
      setError(err.message);
      setSelectedAction(currentStatus); // Reset to previous value on error
    } finally {
      setIsLoading(false);
    }
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <div className="border rounded-lg p-4">
        <h3 className="font-bold text-lg mb-4">Status</h3>
        {["Lit", "Blinking", "Off"].map((action) => (
          <div key={action} className="mb-2">
            <input
              type="radio"
              id={action}
              name="LED Action"
              value={action}
              checked={selectedAction === action}
              onChange={() => setSelectedAction(action)}
              className="mr-2"
              disabled={isLoading}
            />
            <label htmlFor={action}>{action}</label>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-4">
        <LoadingButton
          isLoading={isLoading}
          onClick={performAction}
          disabled={selectedAction === currentStatus}
        >
          {isLoading ? (currentStatus === "" ? "Loading..." : "Saving...") : "Save"}
        </LoadingButton>
      </div>
    </div>
  );
};

export default ConfigurationSettings;
