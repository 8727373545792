import React, { useEffect, useState } from "react";
import axios from "axios";
import uptimeImage from "../../assets/server-update.png";
import temperatureImage from "../../assets/server-temp.png";
import powerImage from "../../assets/pwer-consumed.png";
import healthImage from "../../assets/BMC-health.png";

const StatusCard = ({ imageSrc, title, value, isLoading }) => {
  return (
    <div className="bg-customBlue rounded-lg p-4 flex-1 mx-2 flex items-center space-x-4">
      <div className="w-12 h-12 rounded-full p-1 flex items-center justify-center">
        <img
          src={imageSrc}
          alt={title}
          className="w-full h-full rounded-full object-cover"
        />
      </div>
      <div>
        <h3 className="text-white text-sm font-medium">{title}</h3>
        <p className="text-white text-xl font-bold">
          {isLoading ? "Loading..." : value}
        </p>
      </div>
    </div>
  );
};

const StatusCards = () => {
  const [serverMetrics, setServerMetrics] = useState({
    uptime: "",
    temperature: "",
    powerConsumed: "",
    bmcHealth: "",
  });
  const [loading, setLoading] = useState({
    uptime: true,
    temperature: true,
    powerConsumed: true,
    bmcHealth: true,
  });

  useEffect(() => {
    const fetchUptime = async () => {
      try {
        const uptimeResponse = await axios.get(
          "https://demoapi.asama.cloud/metrics/api/v1/query?query=node_time_seconds%7Binstance%3D%22172.17.0.1%3A8142%22%7D%20-%20node_boot_time_seconds%7Binstance%3D%22172.17.0.1%3A8142%22%7D"
        );
        const uptimeSeconds = uptimeResponse.data.data.result[0].value[1];
        let uptime = "";
        if (uptimeSeconds >= 86400) {
          const days = Math.floor(uptimeSeconds / 86400);
          const remainingHours = Math.floor((uptimeSeconds % 86400) / 3600);
          uptime = `${days} day${days > 1 ? 's' : ''}, ${remainingHours} hour${remainingHours !== 1 ? 's' : ''}`;
        } else if (uptimeSeconds >= 3600) {
          const hours = Math.floor(uptimeSeconds / 3600);
          const remainingMinutes = Math.floor((uptimeSeconds % 3600) / 60);
          uptime = `${hours} hour${hours > 1 ? 's' : ''}, ${remainingMinutes} min${remainingMinutes !== 1 ? 's' : ''}`;
        } else {
          const minutes = Math.floor(uptimeSeconds / 60);
          const remainingSeconds = Math.floor(uptimeSeconds % 60);
          uptime = `${minutes} minute${minutes !== 1 ? 's' : ''}, ${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}`;
        }
        setServerMetrics(prev => ({ ...prev, uptime }));
      } catch (error) {
        console.error("Error fetching uptime:", error);
        setServerMetrics(prev => ({ ...prev, uptime: "Error" }));
      } finally {
        setLoading(prev => ({ ...prev, uptime: false }));
      }
    };

    const fetchTemperature = async () => {
      try {
        const temperatureResponse = await axios.get(
          "https://demoapi.asama.cloud/metrics/api/v1/query?query=last_over_time(redfish_chassis_temperature_celsius{instance=%22172.17.0.1:9142%22,%20sensor=%22CPU1_Temp%22}[30m])"
        );
        const temperature = Math.round(temperatureResponse.data.data.result[0].value[1]);
        setServerMetrics(prev => ({ ...prev, temperature }));
      } catch (error) {
        console.error("Error fetching temperature:", error);
        setServerMetrics(prev => ({ ...prev, temperature: "Error" }));
      } finally {
        setLoading(prev => ({ ...prev, temperature: false }));
      }
    };

    const fetchPower = async () => {
      try {
        const powerResponse = await axios.get(
          'https://demoapi.asama.cloud/metrics/api/v1/query?query=last_over_time(node_hwmon_power_average_watt{instance="172.17.0.1:8142"}[30m])'
        );
        const powerConsumed = Math.round(powerResponse.data.data.result[0].value[1]);
        setServerMetrics(prev => ({ ...prev, powerConsumed }));
      } catch (error) {
        console.error("Error fetching power consumption:", error);
        setServerMetrics(prev => ({ ...prev, powerConsumed: "Error" }));
      } finally {
        setLoading(prev => ({ ...prev, powerConsumed: false }));
      }
    };

    const fetchBMCHealth = async () => {
      try {
        const bmcResponse = await axios.get(
          'https://demoapi.asama.cloud/metrics/api/v1/query?query=last_over_time(redfish_manager_health_state{instance="172.17.0.1:9142"}[30m])'
        );
        let bmcHealth = "";
        if (bmcResponse.data.data.result[0].value[1] === "1") {
          bmcHealth = "OK";
        } else if (bmcResponse.data.data.result[0].value[1] === "2") {
          bmcHealth = "WARNING";
        } else {
          bmcHealth = "CRITICAL";
        }
        setServerMetrics(prev => ({ ...prev, bmcHealth }));
      } catch (error) {
        console.error("Error fetching BMC health:", error);
        setServerMetrics(prev => ({ ...prev, bmcHealth: "Error" }));
      } finally {
        setLoading(prev => ({ ...prev, bmcHealth: false }));
      }
    };

    const fetchAllData = () => {
      fetchUptime();
      fetchTemperature();
      fetchPower();
      fetchBMCHealth();
    };

    fetchAllData();
    const interval = setInterval(fetchAllData, 900000); // Refresh every 15 minutes

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex space-x-4">
      <StatusCard
        imageSrc={uptimeImage}
        title="Server Uptime"
        value={`${serverMetrics.uptime}`}
        isLoading={loading.uptime}
      />
      <StatusCard
        imageSrc={temperatureImage}
        title="Server Temperature"
        value={`${serverMetrics.temperature} °C`}
        isLoading={loading.temperature}
      />
      <StatusCard
        imageSrc={powerImage}
        title="Power Consumed"
        value={`${serverMetrics.powerConsumed} Watts`}
        isLoading={loading.powerConsumed}
      />
      <StatusCard
        imageSrc={healthImage}
        title="BMC Health"
        value={serverMetrics.bmcHealth}
        isLoading={loading.bmcHealth}
      />
    </div>
  );
};

export default StatusCards;
