import React from "react";
import { useNavigate } from "react-router-dom";
import logoImage from "../../assets/Asama.png";
import mikeImage from "../../assets/mike.png";
import headphoneImage from "../../assets/headphone.png";
import glassImage from "../../assets/magnifying-glass.png";
import circleImage from "../../assets/circle.png";

const Header = ({ tagId }) => {
  const navigate = useNavigate();
  return (
    <header className="bg-customGray p-4 pl-6 flex items-center justify-between">
      <div
        className="flex items-center hover:cursor-pointer"
        onClick={() => navigate("/")}
      >
        <img
          src={logoImage}
          alt="Logo"
          className="h-12 w-auto mr-2 mt-0 ml-2 p-0"
        />
      </div>

      <div className="flex-grow max-w-md mx-1">
        <div className="relative flex items-center bg-white rounded-full shadow">
          <img
            src={glassImage}
            alt="Search"
            className="absolute w-5 h-5 left-3"
          />
          <input
            type="text"
            placeholder={tagId}
            className="w-full py-2 pl-10 pr-10 rounded-full text-black focus:outline-none"
          />
          <img
            src={mikeImage}
            alt="Voice"
            className="absolute w-5 h-5 right-3"
          />
        </div>
      </div>

      <nav className="flex items-center space-x-5 mr-4">
        <NavItem icon={headphoneImage} text="Support" />
        <NavItem icon={circleImage} text="Me" isProfile={true} />
      </nav>
    </header>
  );
};

const NavItem = ({ icon, text, isProfile = false }) => (
  <div className="flex flex-col items-center">
    <img
      src={icon}
      alt={text}
      className={`${
        isProfile ? "h-12 w-12 mt-0 mb-0 pt-0 pb-0" : "h-8 w-8"
      } mb-1`}
      aria-hidden="true"
    />
    <span className="text-customBlue text-xs">{text}</span>
  </div>
);

export default Header;
