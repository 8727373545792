import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ServerInventory from "./Components/ServerInventory/ServerInventory";
import ConfigurationSettings from "./Components/ConfigurationSettings/ConfigurationSettings";
import Layout from "./Components/Layout/Layout";
import useFetchData from "./Components/UseFetchData";

import "./App.css";

function App() {
  const [tagId, setTagId] = useState("");
  const { data: serverData } = useFetchData();
  useEffect(() => {
    if (serverData && serverData.Server && serverData.Server.length > 0) {
      setTagId(serverData.Server[0].serial);
    }
  }, [serverData]);

  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<Layout tagId={tagId} />}>
            <Route index element={<ServerInventory />} />
            <Route path="configuration-settings" element={<ConfigurationSettings />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
