import databaseImage from "../../assets/database.png";
import ramImage from "../../assets/ram.png";
import graphicImage from "../../assets/graphic-card.png";
import electronicsImage from "../../assets/electronics.png";
import laptopImage from "../../assets/laptop.png";

const inventoryCategories = [
  { name: "Server", icon: laptopImage },
  { name: "Processor", icon: electronicsImage },
  { name: "Memory", icon: ramImage },
  { name: "Storage", icon: databaseImage },
  { name: "Network", icon: graphicImage },
];

export default inventoryCategories;
