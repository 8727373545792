import { useState, useEffect } from "react";
import axios from "axios";

//uncomment thsi function is using the api. also update the api link
const useFetchData = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://demoapi.asama.cloud/dcim/inventory?device_name=VAN2230RA290120242"
        );
        const serverResp = await axios.get(
          "https://demoapi.asama.cloud/dcim/devices/2"
        );
        const inventoryMap = {};
        response.data.forEach(item => {
          const { name } = item;
          if (inventoryMap[name]) {
            inventoryMap[name].push(item);
          } else {
            inventoryMap[name] = [item];
          }
        });
        inventoryMap["Server"] = [serverResp.data];
        setData(inventoryMap);
      } catch (err) {
        console.error("Error fetching data:", err);
        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setError(`Error ${err.response.status}: ${err.response.data}`);
        } else if (err.request) {
          // The request was made but no response was received
          setError("No response received from the server");
        } else {
          // Something happened in setting up the request that triggered an Error
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading, error };
};

//remove this useFetchData function if using the api. Also uncomment import axios at the top
// const useFetchData = (dataSource) => {
//   const [data, setData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         if (typeof dataSource === "function") {
//           const response = await dataSource(); // Simulate API call if it's a function
//           setData(response);
//         } else {
//           setData(dataSource); // Use directly if it's an object
//         }
//       } catch (err) {
//         setError(err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [dataSource]);

//   return { data, loading, error };
// };

export default useFetchData;
